<template>
  <div class="container">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <!--  -->

        <div class="card border-0 bg-light shadow-sm mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col text-right">
                <h4>
                  <span class="badge badge-primary badge-pill"
                    >Upcoming appointments</span
                  >
                </h4>
              </div>
            </div>
            <div class="row mb-3" v-if="busyUpcoming">
              <div class="col text-center">
                <i class="fad fa-spinner fa-spin fa-6x text-primary"></i>
              </div>
            </div>

            <ul
              class="list-group"
              v-if="
                !busyUpcoming && (upcoming.length > 0 || unconfirmed.length > 0)
              "
            >
              <li
                class="list-group-item mb-3"
                v-for="e in unconfirmed"
                :key="e.id"
              >
                <div class="row mb-3">
                  <div class="col my-auto">
                    <span class="badge badge-danger badge-top-right">
                      <i class="fad fa-info-circle mr-1"></i>
                      Unconfirmed
                    </span>
                    <p class="mb-0">
                      {{ e.start | formatDateTime }} - with
                      <span class="font-weight-bold">{{
                        e.practitioner.name
                      }}</span>
                    </p>
                    <p v-if="e.title" class="mb-0">
                      {{ e.title }}
                      <span v-if="e.consultation && e.consultation.package">
                        ({{ e.consultation.package.name }})
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  class="row mb-3 mt-1"
                  v-if="e.consultation && (e.consultation.method === 'other' || e.consultation.method === 'video')"
                >
                  <div class="col">
                    <p
                      v-if="e.consultation.external_link"
                      class="alert alert-info"
                    >
                      <a :href="e.consultation.external_link">{{
                        e.consultation.external_link
                      }}</a>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto my-auto">
                    <button
                      class="btn btn-sm btn-light"
                      @click="cancelBooking(e)"
                      :disabled="true"
                    >
                      <i class="fad fa-times mr-2"></i>Cancel
                    </button>
                  </div>
                </div>
              </li>
              <li
                class="list-group-item mb-3"
                v-for="e in upcoming"
                :key="e.id"
              >
                <div class="row mb-3">
                  <div class="col my-auto">
                    <p class="mb-0">
                      {{ e.start | formatDateTime }} - with
                      <span class="font-weight-bold">{{ e.user.name }}</span>
                      <span
                      @click="$router.push('orders?id=' + e.consultation.order.id)"
                      class="text-capitalize badge p-1 cursor-pointer"
                      style="position: absolute; right: 18px"
                      :class="
                        e.consultation.order.status === 'paid'
                          ? 'badge-primary'
                          : 'badge-danger'
                      "
                    >
                      {{ e.consultation.order.status | formatStatus }}
                    </span>
                    </p>
                    <p v-if="e.title" class="mb-0">
                      {{ e.title }}
                      <span v-if="e.consultation && e.consultation.package">
                        ({{ e.consultation.package.name }})
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  class="row mb-3"
                  v-if="e.consultation && (e.consultation.method == 'video' || e.consultation.method == 'other')"
                >
                  <div class="col-auto my-auto">
                    <p
                        v-if="e.consultation.external_link"
                        class="alert alert-info"
                    >
                      <a :href="e.consultation.external_link">{{
                          e.consultation.external_link
                        }}</a>
                    </p>
                    <router-link
                        v-else
                      :to="'/consultations/' + e.consultation.id"
                      class="btn btn-primary btn-sm"
                      target="_blank"
                    >
                      <i class="fad fa-webcam mr-2"></i>Start Video Consultation
                    </router-link>

                  </div>
                </div>
                <div class="row">
                  <div class="col-auto my-auto">
                    <button
                      class="btn btn-sm btn-light"
                      @click="cancelBooking(e)"
                      :disabled="true"
                    >
                      <i class="fad fa-times mr-2"></i>Cancel
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <div v-else class="row my-4">
              <div class="col text-center" v-if="!busyUpcoming">
                <i class="fad fa-calendar-check fa-5x text-primary mb-4"></i>
                <h5 class="mb-0">You have no upcoming appointments</h5>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"],
  data() {
    return {
      upcoming: [],
      unconfirmed: [],
      busyUpcoming: false,
    };
  },
  methods: {
    cancelBooking(event) {
      if (
        confirm(
          "Are you sure you wish to cancel this appointment, the practitioner will be notified?"
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/client/appointments/cancel-appointment",
            {
              event_id: event.id,
            }
          )
          .then(({ data }) => {
            this.fetchUpcoming();
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    fetchUpcoming() {
      this.busyUpcoming = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/client/appointments/fetch-appointments"
        )
        .then(({ data }) => {
          this.upcoming = data.events;
          this.unconfirmed = data.unconfirmed;
          this.busyUpcoming = false;
        });
    },
  },
  mounted() {
    this.fetchUpcoming();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
    formatDateTime(date) {
      return moment.utc(date).local().format("lll");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
};
</script>

<style>
</style>
